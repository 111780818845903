.card {
    height: 100%;
    width: 100%;

    transform-style: preserve-3d;
    cursor: pointer;
    transition: transform 1s;

    position: relative;
}

.card:hover {
    transform: rotateY(180deg);
}

.card-front,
.card-back {
    height: 100%;
    width: 100%;

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    overflow: hidden;

    position: absolute;

    display: grid;
    justify-content: center;
    vertical-align: middle;
    text-align: center;
    align-items: center;

    border-radius: 10px;
    border: 5px solid white;
    box-shadow: 0px 3px 18px 3px rgba(0, 0, 0, 0.5);
}

.card-back{
    background-color: #ffffff;
    transform: rotateY(180deg)
}
.card-front{
    background-color: #ffffff;
}